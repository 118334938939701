import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import Technologies from '../components/Technologies'

import customerPic from '../assets/images/customer.jpg'
import craftPic from '../assets/images/craft.jpg'
import qualityPic from '../assets/images/quality.jpg'
import techPic from '../assets/images/tech.jpg'
import securityPic from '../assets/images/security.jpg'
import acessPic from '../assets/images/accessibility.jpg'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Stumbave Ltd"
                    meta={[
                        { name: 'description', content: 'Web Development' },
                        { name: 'keywords', content: 'software, development' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="values" className="tiles">
                        <article style={{ backgroundImage: `url(${customerPic})` }}>
                            <header className="major">
                                <h3>Client Oriented</h3>
                                <p>Client expectations are our highest priority</p>
                            </header>
                        </article>
                        <article style={{ backgroundImage: `url(${craftPic})` }}>
                            <header className="major">
                                <h3>Craftmanship</h3>
                                <p>We take pride in the software that we produce</p>
                            </header>
                        </article>
                        <article style={{ backgroundImage: `url(${qualityPic})` }}>
                            <header className="major">
                                <h3>Quality</h3>
                                <p>We guarantee a high quality and test covered solutions</p>
                            </header>
                        </article>
                        <article style={{ backgroundImage: `url(${techPic})` }}>
                            <header className="major">
                                <h3>Technologies</h3>
                                <p>Latest technologies enhances our success in the industry</p>
                            </header>
                        </article>
                        <article style={{ backgroundImage: `url(${securityPic})` }}>
                            <header className="major">
                                <h3>Security</h3>
                                <p>Applications are built with best security practices</p>
                            </header>
                        </article>
                        <article style={{ backgroundImage: `url(${acessPic})` }}>
                            <header className="major">
                                <h3>Accessibility</h3>
                                <p>We make our applications available to everyone</p>
                            </header>
                        </article>
                    </section>
                    {/* <Technologies /> */}
                </div>

            </Layout>
        )
    }
}

export default HomeIndex