import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>Stumbave - web done right</h1>
            </header>
            <div className="content">
                <p>We build web solutions using latest technologies with emphasis on <b>quality</b> to enhance <b>your</b> business growth</p>
                <ul className="actions">
                    <li><a href="#contact" className="button next scrolly">Contact</a></li>
                </ul>
            </div>
        </div>
    </section>
)

export default Banner
